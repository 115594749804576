import { graphql, Link, useStaticQuery } from "gatsby";
import React, { ReactElement, useContext, useState } from "react";
import LoginContainer from "../components/forms/loginFormContainer";
import { MixpanelContext } from "../metrics";
import isEmail from "validator/lib/isEmail";
import { useForm } from "react-hook-form";
import { AppWriteVerifyUrl } from "../constants";
import { Button, PasswordInput, TextInput } from "luxd";
import { AppwriteContext } from "../appwriteContext";
import { ApiCall } from "../appwrite/appwrite";
import { Token, Error, User } from "../types";
import { gotoAccount, gotoVerify } from "../utils/navigation";
import { toast } from "react-toast";

type LoginFormInputs = {
    email: string;
    password: string;
};

const LoginForm = ({ location }: any): ReactElement => {
    const mixpanel = useContext(MixpanelContext);

    const data = useStaticQuery(graphql`
        {
            allContentfulLayoutLoginForm {
                edges {
                    node {
                        id
                        heading
                        emailFieldLabel
                        passwordFieldLabel
                        forgotPasswordText
                        forgotPasswordUrl
                        signInButtonText
                        childContentfulLayoutLoginFormSubHeadingRichTextNode {
                            childContentfulRichText {
                                html
                            }
                        }
                        backgroundImage {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const { register, handleSubmit, errors } = useForm<LoginFormInputs>();

    const [status, setStatus] = useState<"idle" | "loading" | "success">(
        "idle"
    );

    const sendVerificationEmail = () => {
        ApiCall<Token>(
            appwrite.account.createVerification(AppWriteVerifyUrl),
            (_resp: Token) => {
                setStatus("success");
                appwrite.account.updatePrefs({
                    verificationSent: true,
                });
            },
            (err: Error) => {
                toast.error(
                    "Verification email failed to send." + " " + err.message
                );
            }
        );
    };

    const appwrite = useContext(AppwriteContext);

    const onSubmit = (data: LoginFormInputs) => {
        setStatus("loading");
        ApiCall<User>(
            appwrite.account.createSession(data.email, data.password),
            _sessionResp => {
                appwrite.account.get().then(userResp => {
                    const user: User = userResp as User;
                    if (
                        !user.emailVerification &&
                        user.prefs.verificationSent !== true
                    ) {
                        mixpanel.track("Login", { emailVerified: false });
                        sendVerificationEmail();
                        gotoVerify(true);
                    } else {
                        mixpanel.track("Login", { emailVerified: true });
                        gotoAccount(true);
                    }
                });
            },
            (err: Error) => {
                toast.error(err.message);
                setStatus("idle");
            }
        );
    };
    const content = data.allContentfulLayoutLoginForm.edges[0].node;

    return (
        <LoginContainer
            backgroundImage={`url(${content.backgroundImage.file.url})`}
        >
            <h1 className="text-offWhite font-medium text-2xl text-center font-display mt-2">
                Log into your Streamlux account
            </h1>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-6 flex flex-col space-y-4"
            >
                <div className="flex flex-col space-y-4 font-body">
                    <TextInput
                        id="email"
                        name={"email"}
                        label={"Email address"}
                        placeholder={"Email address"}
                        type="email"
                        required
                        inputMode="email"
                        ref={register({
                            required: "This field is required",
                            validate: value =>
                                isEmail(value) || "Enter valid email address",
                        })}
                        error={errors.email?.message}
                    />
                    <PasswordInput
                        name="password"
                        label="Password"
                        id="password"
                        ref={register({
                            required: true,
                            minLength: {
                                value: 8,
                                message:
                                    "Password must be at least 8 characters",
                            },
                        })}
                        error={errors.password?.message}
                    />
                    <p className="mt-12 text-lg text-right font-display text-offWhite">
                        <Link
                            to="/forgot"
                            className="text-site-secondary font-extrabold hover:text-aqua transition-all duration-100 focus:underline focus:outline-none"
                        >
                            Forgot password?
                        </Link>
                    </p>
                </div>
                <div className="pb-2 pt-4 flex flex-row w-full justify-center">
                    <Button
                        loading={status !== "idle"}
                        color="primary"
                        size="normal"
                        className='w-full'
                    >
                        Log in
                    </Button>
                </div>
            </form>
            <p className="mt-4 text-lg text-center font-display text-offWhite">
                Don't have an account?{" "}
                <Link to="/signup" state={location.state} className="site-link">
                    Sign up
                </Link>
            </p>
        </LoginContainer>
    );
};

export default LoginForm;
